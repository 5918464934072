<template>
  <div class="px-4.5 mt-3">
    <p class="font-bold text-mdlh text-grey-dark-1">{{ nameType }}</p>
    <div class="p-2 space-y-3">
      <div class="flex space-x-2 flex-grow items-center">
        <InputField
          label="From Address"
          :defaultValue="message.from"
          :placeholder="message.from"
          class="w-1/2"
          :disabled="true"
          data-testid="address-field"
        />
        <Select
          label="Audience"
          :options="audienceOptions"
          :value="selectedAudience"
          :preselectFirst="false"
          :onSelect="(value) => (selectedAudience = value)"
          class="w-1/2"
          data-testid="audience-select"
        />
      </div>
      <div class="flex space-x-2">
        <InputField
          label="Subject"
          :onChange="(value) => (subject = value)"
          :defaultValue="subject"
          :propValue="subject"
          :isTextOk="subjectError"
          errorText="Subject is required."
          class="flex-grow"
          data-testid="subject-field"
        />
        <Select
          label="Timezone"
          v-model="timezone"
          :options="timezoneOptions"
          :preselectFirst="false"
          :onSelect="(value) => (timezone = value)"
          class="mr-2 w-28 flex-grow"
          disabled
        />
        <DatePicker
          v-model="sendDate"
          mode="dateTime"
          timezone="UTC"
          :key="sendDate + Math.random()"
          :is-required="true"
          :min-date="minimumDate"
          data-testid="date-select"
          :popover="launchDatePopover"
          :model-config="{
            type: 'string',
            mask: 'YYYY-MM-DDTHH:mm:ss',
            timeAdjust: '06:00:00',
          }"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div>
              <p
                class="text-sm-2 text-grey-light font-medium mb-0.4 relative"
              >
                Send Date
              </p>
              <div class="flex">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  :disabled="isDatePickerDisabled"
                  class="outline-none placeholder-grey-light-2 font-medium text-md text-grey-dark-1 px-2 py-1 border-grey-light-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white"
                />
                <img
                  src="../../../assets/img/icons/date.svg"
                  class="relative right-4"
                />
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <div class="absolute bottom-0 inset-x-0 h-68 opacity-60 bg-white rounded-b-lg" />
          </template>
        </DatePicker>
      </div>
      <div
        class="space-x-2 items-center flex"
        v-if="canRepeat"
      >
        <InputField
          label="Repeat Every"
          :defaultValue="message.nrDays.toString()"
          :propValue="message.nrDays.toString()"
          placeholder="0"
          class="w-1/2"
          type="number"
          :onChange="this.setNrDays"
          :disabled="this.mode == 'edit-launched' || this.mode == 'edit-started'"
          positiveOnly
        />
        <p class="text-grey-dark-1 text-md mt-2">
          days until assessment close date
        </p>
      </div>
      <div>
        <RTF
          label="Message"
          :content="html"
          :haveMinHeight="true"
          :error="messageError && 'Message is required.'"
          :updateHtml="(value) => (html = value)"
          data-testid="message-input"
          :ref="`rich-text-${index}`"
        />
        <div class="flex justify-end space-x-2 mt-2">
          <Button
            :text="
              message.surveyMessageType !== 2 ? 'Reset to Defaults' : 'Delete'
            "
            type="secondary"
            size="medium"
            :onClick="handleReset"
            data-testid="reset-button"
          />
          <Button
            text="Save Progress"
            type="secondary"
            size="medium"
            :onClick="() => save(message, index)"
            :disabled="anyError"
            data-testid="save-button"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import moment from "moment";
import InputField from '../../../components/InputField/InputField.vue';
import Select from '../../../components/Select/NewSelect.vue';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button/Button.vue';
import timezoneOptions from '../../../components/DateTimePicker/timezoneOptions';

Vue.component('date-picker', DatePicker);

const htmlValidator = (value) => value !== '<p></p>';

export default {
  components: { InputField, Select, DatePicker, Checkbox, Button },
  name: 'Email',
  props: {
    index: { type: Number },
    save: { type: Function },
    deleteEmail: { type: Function },
  },
  data: () => ({
    audienceOptions: [
      { label: 'All Participants', id: 0 },
      { label: 'Participants with Assessments in Progress', id: 1 },
      { label: 'Only Managers', id: 2 },
      { label: 'Only Employees', id: 3 },
    ],
    sendDate: moment((new Date(Date.now()).setHours(6, 0, 0))).format("YYYY-MM-DDTHH:mm:ss"),
    message: {},
    minimumDate: undefined,
    timezoneOptions,
  }),
  validations: {
    subject: { required },
    html: { htmlValidator },
  },
  created() {
    this.message = { ...this.messages[this.index] };
    if(this.isDatePickerDisabled)
      this.sendDate = this.message.sendDate;
    this.message = { ...this.message, sendDate: this.sendDate };
    this.handleDatePickerLogic();
  },
  watch: {
    sendDate() {
      this.message = { ...this.message, sendDate: this.sendDate };
    },
  },
  computed: {
    ...mapState({
      messages: (state) => state.assessmentSetup.messages,
      mode: (state) => state.assessmentSetup.mode,
    }),
    ...mapGetters({
      isSeller: "auth/isSeller",
    }),
    nameType() {
      if (this.message.messageName) {
        return this.message.messageName;
      } else
        switch (this.message.surveyMessageType) {
          case 1:
            return 'Invitation Email';
          case 3:
            return 'Reminder Email';
          case 2:
            return 'New Email Message';
          default:
            return 'New Email Message';
        }
    },
    canRepeat() {
      return this.message.surveyMessageType !== 1;
    },
    selectedAudience: {
      get() {
        return this.audienceOptions.filter(
          (item) => item.id === this.message.messageAudience
        )[0];
      },
      set(value) {
        this.message.messageAudience = value.id;
      },
    },
    subject: {
      get() {
        return this.message.subject;
      },
      set(value) {
        this.$v.subject.$touch();
        this.message = { ...this.message, subject: value };
      },
    },
    timezone: {
      get() {
        return this.message.timezone;
      },
      set(value) {
        this.message.timezone = value;
      },
    },
    html: {
      get() {
        return this.message.message;
      },
      set(value) {
        this.$v.html.$touch();
        this.message = { ...this.message, message: value };
      },
    },
    subjectError() {
      if (this.$v.subject.$dirty) {
        if (!this.$v.subject.required) {
          return false;
        }
      }
      return null;
    },
    messageError() {
      if (this.$v.html.$dirty) {
        if (!this.$v.html.htmlValidator) {
          return true;
        }
      }
      return null;
    },
    anyError() {
      return this.subjectError === false || this.messageError;
    },
    isDatePickerDisabled() {
      const disabledMods = ['edit-started', 'edit-launched']
      return disabledMods.includes(this.mode); 
    },
    launchDatePopover() {
      return {
        visibility: this.isSeller && this.isDatePickerDisabled ? 'hidden' : 'hover-focus'
      }
    }
  },
  methods: {
    ...mapActions({
      setMessages: 'assessmentSetup/setMessages',
      resetSurveyCommunicationMessage:
        'assessmentSetup/resetSurveyCommunicationMessage',
    }),
    setNrDays(value) {
        const computedValue = Math.max(0, Number.parseInt(value));
        this.message.nrDays = computedValue;
        this.message.messageFrequency = +(computedValue > 0);
    },
    async reset() {
      await this.resetSurveyCommunicationMessage({
        messageId: this.message.id,
      });
      this.message = {
        ...this.messages[this.index],
      };
      if (+new Date(this.messages[this.index].sendDate) > +new Date(Date.now()))
        this.sendDate = this.messages[this.index].sendDate;
      this.$refs[`rich-text-${this.index}`].editor.commands.setContent(
        this.message.message
      );
    },
    handleReset() {
      if (this.message.surveyMessageType !== 2) {
        this.reset();
      } else {
        this.deleteEmail(this.index);
      }
    },
    handleDatePickerLogic() {
      let today = new Date(Date.now());

      if (Date.parse(this.sendDate) - today.setUTCHours(0, 0, 0) < 0) {
        if (this.mode === 'set-up') {
          this.sendDate = today;
          this.minimumDate = today;
        } else {
          this.minimumDate = undefined;
        }
      } else {
        this.minimumDate = today;
      }
    },
  },
};
</script>
